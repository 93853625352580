import React, { useEffect, useState } from 'react'
import HeroWidget from '../src/HeroWidget'
import { Boards } from "../src/BoardList"
import MetaTags  from "../src/MetaTags"
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { listBoards } from '../src/Firebase'
import LayoutFirebase from "../src/LayoutFirebase"

import { Button } from "../src/LoadingButton"
import Link from "next/link"
import FeaturesWidget from '../src/FeaturesWidget'

import ThreeInOne from '../src/ThreeInOne'
import StorySection from '../src/StorySection'
import MoodBoardFAQ from '../src/MoodBoardFAQ'
import SocialProof from '../src/SocialProof'
import HowItWorks from '../src/HowItWorks'

const Index = ({ boards: boardsJSON }) => {
    const { t } = useTranslation()
    let boards = JSON.parse(boardsJSON)

    return (
      <>
        <MetaTags
          title={t("homepage.title")}
          description={t("homepage.description")}
          image="/assets/Mood-Board-Generator-Header-V2_720.jpg"
          video="/assets/Mood-Board-Generator-Header-V2_720.mp4"
          videoWidth="2038"
          videoHeight="1528"
        />
        <LayoutFirebase bgColor="bg-[#f7f6f4]/80 backdrop-blur-md">
          <HeroWidget />
          <SocialProof />
          <FeaturesWidget />
          <HowItWorks />
          
          <ThreeInOne />
          <StorySection />
          <MoodBoardFAQ />
        </LayoutFirebase>
      </>
    )}
export default Index

export const getStaticProps = async ({ locale }) => {
  const boards = await listBoards({ limit: 4, filterUser: "OnvnC3v57vgdZM6IlGZQ3CIZmKf2" }) // should be an int when divided by 2
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      boards: JSON.stringify(boards),
    },
    revalidate: 3600 //hourly
  }
}