import React, { useEffect, useContext, lazy, Suspense } from "react"
import Image from "next/image"

import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { Chair } from "@styled-icons/material-outlined/Chair"
import Link from "next/link"

const HeroWidget = props => {
  const router = useRouter()
  const { t } = useTranslation()

  const onCreate = () => {
    router.push(`/new-board`)
  }

  return (
    <>
      <section className="overflow-hidden bg-[#f7f6f4]">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center -m-6 pt-16 pb-20">
            {/* Reduced padding-top and padding-bottom */}
            <div className="w-full lg:w-1/2 p-6">
              <div className="lg:max-w-xl">
                <div className="overflow-x-auto pb-4 mb-8">
                  <div className="flex space-x-4 w-max">
                    <a href="/ai-generator">
                      <p className="font-heading max-w-max px-4 py-2.5 font-black text-xs tracking-wider text-pink-800 bg-gradient-to-r from-pink-100 to-gray-50 rounded-full cursor-pointer">
                        Mood Board AI
                      </p>
                    </a>
                    <a href="/mood-board-editor">
                      <p className="font-heading max-w-max px-4 py-2.5 font-extralight text-xs tracking-wider text-gray-400 bg-gradient-to-r from-gray-100 to-gray-50 rounded-full cursor-pointer">
                        Board Editor
                      </p>
                    </a>
                    <a href="/interior-design-ai">
                      <p className="font-heading max-w-max px-4 py-2.5 font-extralight text-xs tracking-wider text-gray-400 bg-gradient-to-r from-blue-100 to-gray-50 rounded-full cursor-pointer">
                        MyRoomDesigner.AI
                      </p>
                    </a>
                  </div>
                </div>
                <div className="flex items-center mb-8">
                  <div className="flex -m-0.5 mr-3">
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                    <div className="p-0">
                      <svg
                        width={17}
                        height={16}
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.707 1.21267C8.02812 0.224357 9.42632 0.224357 9.74744 1.21267L10.8948 4.74387C11.0384 5.18586 11.4503 5.48511 11.915 5.48511H15.6279C16.6671 5.48511 17.0992 6.81488 16.2585 7.42569L13.2547 9.60809C12.8787 9.88126 12.7214 10.3654 12.865 10.8074L14.0123 14.3386C14.3335 15.327 13.2023 16.1488 12.3616 15.538L9.35775 13.3556C8.98178 13.0824 8.47266 13.0824 8.09669 13.3556L5.09287 15.538C4.25216 16.1488 3.12099 15.327 3.44211 14.3386L4.58947 10.8074C4.73308 10.3654 4.57575 9.88126 4.19978 9.60809L1.19596 7.42569C0.355248 6.81488 0.787317 5.48511 1.82649 5.48511H5.53942C6.00415 5.48511 6.41603 5.18586 6.55964 4.74387L7.707 1.21267Z"
                          fill="#be185d"
                        />
                      </svg>
                    </div>
                  </div>
                  <h1 className="text-gray-900 text-xs font-bold">
                    The Nr.1 AI Mood Board Designer
                  </h1>
                </div>
                <div className="flex items-center mb-0">
                  <div className="flex -m-0.5">
                    <div className="p-0.5" />
                  </div>
                </div>
                <h1 className="mb-6 font-heading text-7xl md:text-10xl xl:text-12xl text-gray-900">
                  Create Stunning Mood Boards in Seconds
                </h1>
                <p className="mb-9 text-gray-900 text-lg">
                  Our AI-Powered Moodboard Maker simplifies the process—just
                  choose your style, and let our smart technology craft
                  beautiful, professional mood boards tailored to you. Say
                  goodbye to design stress and hello to creativity!
                </p>
                <div className="flex space-x-4">
                  <button className="group relative font-heading block py-2 px-5 text-base rounded-full cursor-pointer text-white font-heading bg-gradient-to-r from-pink-900 to-pink-700 hover:ring-2 hover:ring-pink-300 }">
                    <a className="relative z-10 " href="/ai-generator">
                      Start to create now
                    </a>
                  </button>
                  {/* New minimalistic button */}
                  <button className="flex items-center space-x-2 text-gray-900 font-heading text-xs transition hover:text-gray-700">
                    <a
                      href="#how-moodboard-ai-works"
                    >
                      Learn how it works
                    </a>
                    <svg
                      className="w-4 h-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
                {/* Features List */}
                <ul className="flex flex-col items-start mt-8 text-black">
                  <li className="flex items-center mb-2">
                    {/* Add mb-4 for spacing */}
                    <div className="bg-green-100 rounded-full p-1 flex justify-center items-center">
                      <svg
                        className="w-5 h-5 text-black"
                        fill="none"
                        viewBox="0 0 19 19"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <a
                      className="font-heading text-black text-base ml-2"
                      href="/ai"
                    >
                      Free moodboard gallery
                    </a>
                  </li>
                  <li className="flex items-center">
                    <div className="bg-green-100 rounded-full p-1 flex justify-center items-center">
                      <svg
                        className="w-5 h-5 text-black"
                        fill="none"
                        viewBox="0 0 19 19"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="font-heading text-black text-base ml-2">
                      Cancel anytime
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-1/2 p-6">
              <Image
                className="block mx-auto"
                src="https://static.shuffle.dev/uploads/files/29/29ed9324f34ff57161e62e45b1e365a6c2cf9f02/The-Best-Mood-Board-Creator.png"
                alt="Best Mood Board Creator"
                layout="responsive"
                width={100}
                height={100}
              />
            </div>
          </div>
        </div>
      </section>
     
    </>
  )
}

export default HeroWidget